import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Product } from '../api/dataUtil';

interface SizeDropdownProps {
    product: Product;
}

export default function SizeDropdown({ product }: SizeDropdownProps) {

    const navigate = useNavigate();
    const sizes = product.sizes;
    const [selectedSize, setSelectedSize] = useState(product.selected_size);

    useEffect(() => {
        setSelectedSize(selectedSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedSize(event.target.value);
        let path = `/product/${product.sku.substring(0, 2)}-`;

        if (product.sku.substring(0, 2) === "BO" || product.sku.substring(0, 2) === "BB" || product.sku.substring(0, 2) === "BU") {
            if (event.target.value.toLocaleLowerCase().includes("rustic pines")) {
                path += "01-RP";
            } else if (event.target.value.toLocaleLowerCase().includes("midnight bourbon")) {
                path += "01-MB";
            } else if (event.target.value.toLocaleLowerCase().includes("unscented")) {
                path += "01-UN";
            }
            navigate(path);
        }
    };

    return (
        <div>
            <label htmlFor="size">Select: </label>
            <select id="size" name="size" className="size-dropdown" onChange={handleChange} value={selectedSize}>
                {sizes.map((size: string) => (
                    <option value={size} key={size}>{size}</option>
                ))}
            </select>
        </div>
    );
}
