import React, { useContext } from "react";
import Features from "./Features";
import Buy from "./Buy";
import Aside from "./Aside";
import Hero from "./Hero";
import LogoTransition from "./LogoTransition";
import Testimonials from "./Testimonials";
import { ProductsContext } from "../api/DatabaseComponent";
import TinyStore from "./TinyStore";
import Spotlight from "./Spotlight";
// import FloatingShopButton from "../checkout/FloatingShopButton";

const Landing: React.FC = () => {
	//export default function Landing() {
	const products = useContext(ProductsContext);
	return (
		<>
			<Hero />
			<TinyStore products={products} category={"grooming"} />
			<Spotlight />
			<LogoTransition />
			<Testimonials />
			<section className="py-5 bg-light bg-texture-03-light">
				<div className="container px-5 my-5">
					<div className="row gx-5 align-items-center">
						<Features />
					</div>
				</div>
			</section>
			<Buy products={products} sku='BO-01-MB' />
			

			<section className='py-5'>
				<div className='container px-5 my-5'>
					<Aside />
				</div>
			</section>
			{/* <Spotlight /> */}
			{/* <FloatingShopButton startingState={0.75} targetOpacity={.75} scrollTrigger={100} visibilityTrigger={500} /> */}
		</>
	);
}

export default Landing;