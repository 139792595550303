// Helper function to import all images

const importAll = (requireContext: __WebpackModuleApi.RequireContext) => requireContext.keys().map(requireContext);

// Preload images for specific paths
const productImages = importAll(require.context('../../images/product_images', false, /\.(png|jpe?g|svg)$/));
const buyImages = importAll(require.context('../../images/buy_images', false, /\.(png|jpe?g|svg)$/));
const productLabelImages = importAll(require.context('../../images/labels', false, /\.(png|jpe?g|svg)$/));
export const iconImages = importAll(require.context('../../images/icons', false, /\.(png|jpe?g|svg)$/));
export const defaultImage = 'https://dummyimage.com/600x600/dee2e6/6c757d.jpg';

// Function to get images based on images_path
export const getImagesByPath = (sku: string): string[] => {

    sku = sku.toLowerCase() === "bo-dv-dv" ? "bo-01-rp" : sku;

    let imageList = Object.values(productImages).filter(file => file.includes(sku.toLowerCase()));

    if (imageList.length === 0) {
        if (sku.toLowerCase().includes("bo")) {
            imageList = Object.values(productImages).filter(file => file.includes("bo-01-rp"));
        } else if (sku.toLowerCase().includes("bb")) {
            imageList = Object.values(productImages).filter(file => file.includes("bb-01-rp"));
        } else if (sku.toLowerCase().includes("bu")) {
            imageList = Object.values(productImages).filter(file => file.includes("bu-01-rp"));
        }
    }
    if (!sku.toLowerCase().includes("bu")) {
        imageList = [...imageList, ...getProductLabelImages(sku)];
    }


    // Append matching label images to the imageList
    return imageList.length > 0 ? imageList : [defaultImage];
};

const getProductLabelImages = (sku: string): string[] => {
    if (sku.toLowerCase().includes("bu")) {
        let combinedImages = Object.values(productLabelImages).filter(file => file.includes("bo-01-rp"));
        return [...combinedImages, ...Object.values(productLabelImages).filter(file => file.includes("bb-01-rp"))];
    } else {
        return Object.values(productLabelImages).filter(file => file.includes(sku.toLowerCase()));
    }
}

export const getBuyImageByPath = (sku: string): string => {
    const image = Object.values(buyImages).find(file => file.includes(sku.toLowerCase()));
    return image !== '' ? image : defaultImage;
}