import React from 'react'
import { Link } from "react-router-dom";
import logo from "../../images/logos/mustache.png";

export default function LogoTransition() {
    return (
        <section className="">
            <div className="container px-5 my-5">
                <div className="row gx-5 d-flex justify-content-center">
                    <div className="d-flex justify-content-center">
                        <img
                            className='image-transition'
                            src={logo}
                            alt='...'
                        /></div>
                </div>
                <div className="row gx-5 justify-content-center">
                    <div className="col-lg-6">
                        <div className="text-center mb-5">
                            <p className="lead fw-normal text-muted pb-2 fst-italic">
                            At Heartland Supply Co., we proudly embrace our Midwest roots. For us, this means celebrating and honoring the rich traditions of our region. We commit to this heritage by meticulously handcrafting each of our products to meet the highest standards of quality, ensuring that every item reflects the integrity and spirit of the Midwest.
                            </p>
                            <Link
                                className="btn btn-info btn-lg btn-width bg-gradient"
                                to="/about"
                            >
                                About Us
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            
        </section>
    )
}
