import spotlightImage from "../../images/stock/fortville_barbershop.jpg";

const spotlightText = "Heartland Supply Co. is honored to be part of Fortville Barbershop's journey as they open their doors to the community. We're grateful to have our products featured in their shop, bringing our handcrafted grooming essentials to an even wider audience. Stop by to support this incredible local business and check out our lineup of beard oils, balms, and more—all made with care and rooted in quality.";

export default function Spotlight() {
	return (
		<section className="py-5 mt-5 secondary-background bg-texture-01-black">
			<div className="container px-5 my-5">
				<div className="row gx-5">
					<div className="col-12">
						<div className="text-center">
							<h1 className="fw-bolder text-light">Spotlight</h1>
							<h3 className="section-subheading text-light">Now Available at Fortville Barbershop</h3>
						</div>
						<img className="img-fluid rounded-3 mb-5" src={spotlightImage} alt="..." />
					</div>
				</div>
				<div className="row gx-5 justify-content-center">
					{/* <div className="col-lg-6"> */}
					<div className="col-12">
						<div className="text-center">
							<p className="lead fw-normal text-white mb-4">
								{spotlightText}
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
