import React from "react";
import america_icon from "../../images/logos/feature-america.png";
import natural_icon from "../../images/logos/feature-natural.png";
import corn_icon from "../../images/logos/feature-corn.png";
import chemicals_icon from "../../images/logos/feature-chemicals.png";
import corners_icon from "../../images/logos/feature-corners.png";
import hunting_icon from "../../images/logos/feature-hunting.png";


export default function Features() {
	return (
		<section className="mt-5" id="features">
			<div className="container">
				<div className="text-center">
					<h1 className="fw-bolder">OUR FORMULA</h1>
					<h3 className="section-subheading text-dark">Each product we sell comes with a few promises:</h3>
				</div>
				<div className="row text-center">
					<div className="col-md-4">
						<span className="fa-stack fa-4x">
							<img src={america_icon} alt="America Icon" />
						</span>
						<h4 className="my-3 fw-bolder">Made in the U.S.A.</h4>
						<p className="text-muted">All our products are proudly handcrafted in the Midwest USA.</p>
					</div>
					<div className="col-md-4">
						<span className="fa-stack fa-4x">
							<img src={natural_icon} alt="Natural Ingredients Icon" />
						</span>
						<h4 className="my-3 fw-bolder">Natural Ingredients</h4>
						<p className="text-muted">
							Each of our ingredients is carefully selected to provide our customers with the purest and highest quality products they can trust.
						</p>
					</div>
					<div className="col-md-4">
						<span className="fa-stack fa-4x">
							<img src={corn_icon} alt="Locally Sourced Icon" />
						</span>
						<h4 className="my-3 fw-bolder">Locally Sourced</h4>
						<p className="text-muted">
							We take pride in America's heartland. Ingredients sourced directly from the Midwest and indigenous to the region are prioritized over cheaper solutions.
						</p>
					</div>
				</div>
				<div className="row text-center mt-4">
					<div className="col-md-4">
						<span className="fa-stack fa-4x">
							<img src={chemicals_icon} alt="No Unncessessary Chemicals Icon" />
						</span>
						<h4 className="my-3 fw-bolder">No Unnecessary Chemicals</h4>
						<p className="text-muted">
							No unnecessary ingredients or harmful preservatives are ever used in our products.
						</p>
					</div>
					<div className="col-md-4">
						<span className="fa-stack fa-4x">
							<img src={corners_icon} alt="No Cutting Corners Icon" />
						</span>
						<h4 className="my-3 fw-bolder">No Cutting Corners</h4>
						<p className="text-muted">
							We will never cut corners or sacrifice quality to save costs.
						</p>
					</div>
					<div className="col-md-4">
						<span className="fa-stack fa-4x">
							<img src={hunting_icon} alt="Hunter Friendly Icon" />
						</span>
						<h4 className="my-3 fw-bolder">Hunter Friendly</h4>
						<p className="text-muted">
							No artificial or unnatural fragrances are used in our products.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}
