import { generateGUID } from "../store/CartSlice";
import masterCard from "../../images/payments/MasterCard.jpg";
import amEx from "../../images/payments/american-express.jpg";
import amazon from "../../images/payments/amazon-pay.jpg";
import cash from "../../images/payments/cash-app.jpg";
import visa from "../../images/payments/visa.jpg";
import dinersClub from "../../images/payments/diners-club.jpg";
import klarna from "../../images/payments/klarna.jpg";

export const websiteURL: string = 'http://localhost:3000/';
export const serverURL: string = process.env.REACT_APP_SERVER_URL || 'http://localhost:4242/';
export const stripeSecret: string = process.env.REACT_APP_STRIPE_SECRET || 'pk_test_51PiPaeLQfgD2l9pcAPVOF8KLGvXFEJAeQ1wo9rGSLHlOeZxUkfbERPgIkaZSiAJBwjPG8WKh3p6ZG6zouUN6HFoD00rG9vZejM';
export const shippingId: string = process.env.REACT_APP_SHIPPING_ID || 'shr_1Q0lgfLQfgD2l9pcsgj0zUSO';
export const shippingRate: number = 0;

export interface Product {
    buy_image: string;
    category: string;
    description: string;
    guid: string;
    handcrafted: boolean;
    how_to: string;
    product_id: string;
    images: string[];
    ingredients: string[];
    key_ingredients: string[];
    product_type: string;
    product_name: string;
    product_price: number;
    price_id: string;
    selected_size: string;
    sizes: string[];
    sku: string;
    quantity: number;
    include: boolean;
    inventory: number;
    msrp: number;
}

export const defaultProduct: Product = {
    product_id: 'default',
    product_type: 'default',
    product_name: 'default',
    product_price: 999.99,
    images: ['https://dummyimage.com/600x600/dee2e6/6c757d.jpg'],
    buy_image: 'https://dummyimage.com/600x600/dee2e6/6c757d.jpg',
    guid: generateGUID(),
    sku: 'default',
    price_id: 'default',
    quantity: 1,
    description: 'default',
    ingredients: ['default'],
    how_to: 'default',
    key_ingredients: ['default'],
    handcrafted: true,
    sizes: [],
    selected_size: '',
    category: 'default',
    include: false,
    inventory: 0,
    msrp: 999.99
};

// Optionally, you can create a lookup function by ID
export const getProductById = (productsList: Product[], id: string): Product | undefined => {
    return productsList.find(product => product.product_id === id);
};

export const getProductBySku = (productsList: Product[], sku: string): Product | undefined => {
    return productsList.find(product => product.sku === sku);
};

export const paymentOptions = {
    visa: {
        alt: 'Visa',
        image: visa
    },
    mastercard: {
        alt: 'MasterCard',
        image: masterCard
    },
    amex: {
        alt: 'American Express',
        image: amEx
    },
    diners: {
        alt: 'Diners Club',
        image: dinersClub
    },
    amazon: {
        alt: 'Amazon Pay',
        image: amazon
    },
    cash: {
        alt: 'Cash App Pay',
        image: cash
    },
    klarna: {
        alt: 'Klarna Pay',
        image: klarna
    }
};