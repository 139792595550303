import React, { useState, useEffect } from "react";

const testimonials = [
    {
        quote: "Most oils irritate my skin. This product DOES NOT have that effect at all! I will continue to buy it for those reasons! Fantastic quality and love the look!",
        author: "Kyle P. from Indianapolis, IN",
    },
    {
        quote: "My husband's beard and skin get dry, itchy and coarse. This has helped it stay softer and his skin feeling better. I loooove how it smells. 🥵",
        author: "Jessica B. from Westfield, IN",
    },
    {
        quote: "I just wanted to say how impressed I am with the oil. One of my all time favorites. The thickness, color, natural scent and effectiveness is just so top notch. It’s by far my nighttime go to and it just feels so great in beard.",
        author: "Chad K. from O'Fallon, MO",
    },
    {
        quote: "The first whiff you get, it smells amazing. It smells pretty good like pine.",
        author: "Blake B. from Enid, OK",
    },
    {
        quote: "The pine scent is perfect! It isn't like some of my other oils where the scent is overwhelming and makes it smell artificial and not natural. The smell and feel of this oil is amazing.",
        author: "Kevin L. from Sheridan, IN",
    },
];

export default function Testimonials() {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
        }, 5000); // Change every 5 seconds
        return () => clearInterval(interval);
    }, []);

    return (
        <section className="py-5 primary-background bg-gradient" id="testimonials-section">
            <div className="container px-5 my-5">
                <div className="row gx-5 justify-content-center">
                    <div className="col-12 text-center">
                        <h1 className="fw-bolder">Customer Feedback</h1>
                        <h3 className="section-subheading text-dark">Read What Our Customers Are Saying</h3>
                        <div className="testimonial-slider">
                            {/* <h2 className="fw-bolder text-secondary">Testimonials</h2> */}

                            {testimonials.map((testimonial, index) => (
                                <div
                                    key={index}
                                    className={`testimonial ${index === currentIndex ? "active" : index < currentIndex ? "inactive" : ""
                                        }`}
                                >
                                    <p className="lead fw-normal text-white mb-0"><i>"{testimonial.quote}"</i></p>
                                    <p className="fw-bolder text-white mb-0">- {testimonial.author}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
