import { Product } from "../api/dataUtil";
import { Link } from "react-router-dom";

interface TinyStoryProps {
    products: Product[];
    category: string;
}

export default function TinyStore({ products, category }: TinyStoryProps) {

    let categoryProducts = products.filter(product => product.category === category);
    let filteredProducts = categoryProducts.filter(product => product.include);

    return (

        <section className="mt-5" id="features">
            <div className="container">
                <div className="text-center mb-4">
                    <h2 className="fw-bolder">Grooming Products</h2>
                    {/* <h4 className="section-subheading text-muted">Each ingredient we use has a purpose. Here are some highlights:</h4> */}
                </div>
                <div className="row text-center">
                    {filteredProducts.map((product: any) => (
                        <div className="col-md-4">
                            <Link className="" to={"/product/" + product.sku}>
                                <img src={product.images[0]} alt="default" className="ingredient-icon" />
                            </Link>
                            <h4 className="my-3 fw-bolder">{product.product_type}</h4>
                            <div className="text-center"><Link className="btn btn-outline-dark mt-auto" to={"/product/" + product.sku}>View Options</Link></div>
                        </div>
                    ))}
                </div>

            </div>
            {/* <hr /> */}
        </section>
    );
}
