import { iconImages } from "../api/imageHelper";

interface KeyIngredientsProps {
	keys: string[];
}

interface Ingredient {
    title: string;
	alt: string;
    description: string;
    image: string; // assuming the image is a string URL or a path
}

interface Ingredients {
    [key: string]: Ingredient;
}

export const ingredientsKeyValue: Ingredients = {
	cedar: {
		title: 'Eastern Red Cedar',
		alt: 'eastern-red-cedar-key-ingredient',
		description: 'Cedarwood thrives across the Midwest, making it a locally abundant and sustainable resource. It adds a warm, earthy aroma while also offering antiseptic and anti-inflammatory properties, keeping your beard and skin healthy.',
		image: Object.values(iconImages).filter(file => file.includes("cedar"))[0]
	},
	jojoba: {
		title: 'Jojoba Oil',
		alt: 'jojoba-oil-key-ingredient',
		description: 'Jojoba may not be a Midwest native, but we won\'t hold that against it. Try as we might, the fact is, Jojoba\'s moisturizing and skin-soothing properties make it one of the most widely used ingredients in beard oil—and for good reason.',
		image: Object.values(iconImages).filter(file => file.includes("jojoba"))[0]
	},
	pine: {
		title: 'Scotch Pine',
		alt: 'pine-key-ingredient',
		description: 'Scotch Pine is a resilient tree that adapts well to the Midwest, offering a sustainable and locally sourced ingredient. Its fresh, invigorating aroma revitalizes the senses, while its antimicrobial and cleansing properties help keep your beard and skin feeling refreshed and healthy.',
		image: Object.values(iconImages).filter(file => file.includes("pine"))[0]
	},
	pumpkin: {
		title: 'Pumpkin Seed Oil',
		alt: 'pumpkin-seed-oil-key-ingredient',
		description: 'Pumpkin seed oil might seem a little "basic," but we promise we’re not just here for the fall vibes. As a true Midwestern staple, pumpkin seed oil is rich in vitamins and antioxidants that nourish and protect your beard, leaving it soft, strong, and healthy year-round.',
		image: Object.values(iconImages).filter(file => file.includes("pumpkin"))[0]
	},
	sunflower: {
		title: "Sunflower Seed Oil",
		alt: "sunflower-seed-oil-key-ingredient",
		description: "Sunflower seed oil, cold-pressed right here in the Midwest, is the perfect base for our formulas. It's lightweight, non-greasy, and deeply nourishes beards, promoting softness and shine.",
		image: Object.values(iconImages).filter(file => file.includes("sunflower"))[0]
	},
	shea: {
		title: "Shea Butter",
		alt: "shea-butter-key-ingredient",
		description: "Shea butter may not be from the Midwest, but its unmatched ability to lock in moisture and soften hair makes it the backbone of our beard balm. It nourishes both skin and beard, keeping dryness and irritation at bay while giving your beard a healthy, well-groomed look.",
		image: Object.values(iconImages).filter(file => file.includes("shea"))[0]
	},
	bourbon: {
		title: "Bourbon Spirit",
		alt: "bourbon-spirit-key-ingredient",
		description: "Crafted with inspiration from the Midwest’s rich microbrewery and distillery community, our bourbon spirit-infused products capture the rugged warmth of bourbon without the booze. This proprietary blend of all-natural essential oils carries the essence of aged oak barrels and evokes the confidence and sophistication of a smoky Midwest evening.",
		image: Object.values(iconImages).filter(file => file.includes("bourbon"))[0]
	},
	bees: {
		title: "Beeswax",
		alt: "beeswax-key-ingredient",
		description: "Harvested from a single source in the heart of the Midwest, our beeswax is as pure as it gets. Known for its natural hold and protective properties, it locks in moisture while taming and shaping your beard.",
		image: Object.values(iconImages).filter(file => file.includes("bees"))[0]
	}
}

const KeyIngredients: React.FC<KeyIngredientsProps> = ({ keys }) => {
	return (
		<section className="mt-5" id="features">
			<div className="container">
				<div className="text-center mb-4">
					<h2 className="fw-bolder">FEATURED INGREDIENTS</h2>
					<h4 className="section-subheading text-muted">Each ingredient we use has a purpose. Here are some highlights:</h4>
				</div>
				<div className="row text-center">
					{keys.map((key: string) => (
						<div className="col-md-4" key={key}>
							{/* <span className="fa-stack fa-4x"> */}
								<img src={ingredientsKeyValue[key].image} alt={ingredientsKeyValue[key].alt} className="ingredient-icon" />
							{/* </span> */}
							<h4 className="my-3 fw-bolder">{ingredientsKeyValue[key].title}</h4>
							<p className="text-muted">{ingredientsKeyValue[key].description}</p>
						</div>
					))}
				</div>
			</div>
		</section>
	);
}

export default KeyIngredients;