import React from 'react'
import Aside from './landing/Aside';
import Features from './landing/Features';
// import FloatingShopButton from './checkout/FloatingShopButton';
import imageLabels from '../images/stock/new-label-sheet.png';
import imageBench from '../images/stock/bottles-on-bench.png';

export default function About() {
  
    return (
        <>
            <header className="py-5 secondary-background bg-texture-01-black">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-xxl-6">
                            <div className="text-center">
                                <h1 className="fw-bolder mb-3 text-primary">Rooted In the Heartland</h1>
                                <p className="lead fw-normal text-white mb-4">At Heartland Supply Co., we proudly embrace our Midwest roots. For us, this means celebrating and honoring the rich traditions of our region. We commit to this heritage by meticulously handcrafting each of our products to meet the highest standards of quality, ensuring that every item reflects the integrity and spirit of the Midwest.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <section className="py-5 bg-white" id="scroll-target">
                <div className="container px-5 my-5">
                    <div className="row gx-5 align-items-center">
                        <div className="col-lg-6"><img className="img-fluid rounded mb-5 mb-lg-0" src={imageBench} alt="..." /></div>
                        <div className="col-lg-6">
                            <h2 className="fw-bolder">Driven by Midwestern Values</h2>
                            <p className="lead fw-normal text-muted mb-0">
                                <ul>
                                    <li>
                                        <span className="text-primary fw-bolder">Celebrating Midwest Traditions:</span> We honor the rich cultural heritage of the Midwest through every product we create.
                                    </li>
                                    <li>
                                        <span className="text-primary fw-bolder">Handcrafted Excellence:</span> Our dedication to craftsmanship ensures superior grooming products that men can depend on.
                                    </li>
                                    <li>
                                        <span className="text-primary fw-bolder">Natural Ingredients:</span> We use the finest natural ingredients, sourcing locally whenever possible to support our community and reduce our environmental impact.
                                    </li>
                                    <li>
                                        <span className="text-primary fw-bolder">Authenticity and Integrity:</span> Our products embody the true spirit of the Midwest, offering authenticity and reliability in every use.
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5 bg-light bg-texture-03-light">
                <div className="container px-5 my-5">
                    <div className="row gx-5 align-items-center">
                        <Features />
                    </div>
                </div>
            </section>
            <section className="py-5 primary-background bg-gradient" id="scroll-target">
                <div className="container px-5 my-5">
                    <div className="row gx-5 align-items-center">
                        <div className="col-lg-6"><img className="img-fluid rounded mb-5 mb-lg-0" src={imageLabels} alt="..." /></div>
                        <div className="col-lg-6">
                            <h2 className="fw-bolder text-secondary">Forging a Legacy</h2>
                            <p className="lead fw-normal text-white mb-0">
                                At Heartland Supply Co., we see ourselves as more than just a men's grooming brand; we aim to be a lifestyle beacon for the Midwest. Our vision is to continuously innovate and expand our product line with offerings that uphold our core values of quality, authenticity, and sustainability. With your support, we strive to strengthen a sense of pride and community in the heartland, catering to the lifestyle and needs of the well-groomed modern Midwestern man.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5 bg-light" id="scroll-target">
                <div className="container px-5 my-5">
                    <div className="row gx-5 align-items-center">
                        <Aside />
                    </div>
                </div>
            </section>
            {/* <FloatingShopButton startingState={1} targetOpacity={0.75} scrollTrigger={100} visibilityTrigger={500} /> */}
        </>
    )
}
